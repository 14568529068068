import React, { useContext, useState } from 'react';
import styled from '@emotion/styled';
//not sure how to use theme props w/ typescript... so being forces to import
//this way. Typescript giving and error (line 28)
import { device } from '../../GlobalStyles';
//components
import { siteContext } from '../../../layouts/SiteContextProvider';
import { MobileNav } from './MobileNav';
import { DesktopNav } from './DesktopNav';
import { NavSlide } from './NavSlide';
// interface Theme {
//     green: string;
// }
const StyledNav = styled.nav`
    position: fixed;
    width: 100%;
    z-index: 100;
    top: 0em;
    background: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(16px);
`;
export const Nav: React.FC = () => {
    const [activeSlide, setActiveSlide] = useState(false);
    const { sidePanels, setSidePanels } = useContext(siteContext);
    return (
        <StyledNav>
            <MobileNav
                setSidePanels={setSidePanels}
                sidePanels={sidePanels}
                setActiveSlide={setActiveSlide}
                activeSlide={activeSlide}
            />
            <NavSlide
                setSidePanels={setSidePanels}
                sidePanels={sidePanels}
                setActiveSlide={setActiveSlide}
                activeSlide={activeSlide}
            />
            <DesktopNav
                setSidePanels={setSidePanels}
                sidePanels={sidePanels}
                setActiveSlide={setActiveSlide}
                activeSlide={activeSlide}
            />
        </StyledNav>
    );
};
