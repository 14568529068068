import React, { useState } from 'react';
//styles
import { colors, device } from './GlobalStyles';
import styled from '@emotion/styled';

const MapContainer = styled.div`
    height: 75vh;
`;

export const Map: React.FC = () => {
    return (
        <MapContainer>
            <iframe
                title="map"
                zoom="20"
                src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=2814%20W%20Waters%20Ave+(My%20Business%20Name)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                width="100%"
                allowFullScreen
                loading="lazy"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
            />
        </MapContainer>
    );
};
