import { useState, createContext, FC, useContext } from 'react';

type UserContextType = {
    sidePanels: boolean;
    setSidePanels: React.Dispatch<React.SetStateAction<boolean>>;
};

interface NavProps {
    children: React.ReactNode;
}

export const siteContext = createContext<UserContextType>({
    sidePanels: false,
    setSidePanels: sidePanels => !sidePanels,
});

export const SiteContextProvider = ({ children }: NavProps) => {
    const [sidePanels, setSidePanels] = useState<boolean>(false);
    return (
        <siteContext.Provider value={{ sidePanels, setSidePanels }}>
            {children}
        </siteContext.Provider>
    );
};
