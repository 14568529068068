/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
//plugins
import { Link } from 'gatsby';
import Modal from 'react-modal';
import { useState } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../GlobalStyles';
import greenArrow from '../../../images/icons/green-arrow-right.svg';

type StyleProps = {
    position: string;
    active: boolean;
    regular?: boolean;
};

const Overlap = styled.div<StyleProps>`
    .spec {
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
    .see-all-button {
        font-size: 15px;
        display: flex;
        align-items: center;
        font-weight: 700;
        margin-top: 1em;
        cursor: pointer;
        color: ${colors.green};
        img {
            cursor: pointer;
            margin-left: 1em;
        }
    }
    background: ${colors.white};
    padding: 2em;
    width: 349px;
    box-shadow: 0px 9px 36px rgba(177, 142, 50, 0.17);
    border-radius: 8px;
    top: ${props => (props.active ? '15vh' : '-10vh')};
    left: ${props => props.position};
    position: relative;
    height: ${({ regular }) => (regular ? '520px' : 'auto')};
    flex-wrap: wrap;
    transition: 0.1s ease-in;
    overflow-y: scroll;
    button {
        padding: 0em;
        border: none;
        background: none;
    }
    label {
        color: ${colors.green};
        font-weight: 700;
        font-size: 24px;
    }
    ul {
        margin: 0px;
        margin-top: 0.5em;
        padding: 0em;
    }
    li {
        text-align: left;
        margin: 0.6em 0em;
        font-size: 18px;
        width: 300px;
        list-style-type: ${({ regular }) => (regular ? 'disc' : 'none')};
        cursor: pointer;
        font-weight: 500;
        &:hover {
            color: ${colors.green};
            opacity: 0.8;
        }
    }
    @media (min-width: 1500px) {
        height: ${({ regular }) => (regular ? '551px' : 'auto')};
        li {
            font-size: 21px;
        }
    }
`;
const General = styled(Overlap)<StyleProps>`
    top: ${props => (props.active ? '15vh' : '-10vh')};
    left: 2em;
    margin: 0em;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
`;

const Cosmetic = styled(Overlap)<StyleProps>`
    border-radius: 0;
    top: ${props => (props.active ? '15vh' : '-10vh')};
    position: absolute;
    left: 22.5em;
`;

const Implants = styled(Overlap)<StyleProps>`
    top: ${props => (props.active ? '15vh' : '-10vh')};
    position: absolute;
    left: 42.8em;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
`;

type Dropdown = {
    id: number;
    name: string;
};

type Links = {
    name: string;
    dropdown: Dropdown;
};

type Props = {
    position: string;
    active: boolean;
    links: Links;
    setActive(arg: boolean): void;
    //the value after the colon is what the function is expected to return
};

export const DesktopModal: React.FC<Props> = ({
    links,
    position,
    setActiveSlide,
    active,
    setActive,
}) => {
    const [hovered, setHovered] = useState(false);
    type PagePath = {
        url: string;
        name: string;
    };
    return (
        <Modal
            className="open"
            isOpen={active}
            shouldCloseOnOverlayClick={true}
            onRequestClose={() => setActive(false)}
            style={
                {
                    overlay: {
                        width: '100vw',
                        backgroundColor: 'rgba(0,0,0,0.3)',
                    },
                    content: {
                        top: '10vh',
                        width: '230px',
                        height: '1vh',
                        backgroundColor: 'none',
                    },
                } as Modal.Styles
            }
        >
            {links.name === 'SERVICES' ? (
                <div className="try">
                    <General hovered={hovered} active={active} position={position}>
                        <Link to="/general-dentistry/">
                            <label className="spec" onClick={() => setActive(false)}>
                                {links.dropdown[0].name}
                            </label>
                        </Link>
                        <ul>
                            {links.dropdown[0].subdropdown.map(
                                (subsublink: { name: string | undefined; url: string }) => (
                                    <button key={subsublink.name}>
                                        <Link to={subsublink.url}>
                                            <li onClick={() => setActive(false)}>
                                                {subsublink.name}
                                            </li>
                                        </Link>
                                    </button>
                                )
                            )}
                            {/* <Link to="/services">
                                <button
                                    onMouseEnter={() => setHovered(true)}
                                    onMouseLeave={() => setHovered(false)}
                                    onClick={() => setActive(false)}
                                    className="see-all-button"
                                >
                                    SEE ALL{' '}
                                    <img src={greenArrow} alt="green arrow pointing right" />
                                </button>
                            </Link> */}
                        </ul>
                    </General>
                    <Cosmetic hovered={hovered} active={active} position={position}>
                        <Link to="/cosmetic-dentistry/">
                            {/* // eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                            {/* // eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
                            <label className="spec" onClick={() => setActive(false)}>
                                {links.dropdown[1].name}
                            </label>
                        </Link>
                        <ul>
                            {links.dropdown[1].subdropdown.map(subsublink => (
                                <button key={subsublink.name}>
                                    <Link to={subsublink.url}>
                                        <li onClick={() => setActive(false)}>{subsublink.name}</li>
                                    </Link>
                                </button>
                            ))}
                        </ul>
                    </Cosmetic>
                    <Implants hovered={hovered} active={active} position={position}>
                        <Link to="/dental-implants/">
                            <label className="spec" onClick={() => setActive(false)}>
                                {links.dropdown[2].name}
                            </label>
                        </Link>
                        <ul>
                            {links.dropdown[2].subdropdown.map(subsublink => (
                                <button onClick={() => setActiveSlide(false)} key={subsublink.name}>
                                    <Link to={subsublink.url}>
                                        <li onClick={() => setActive(false)}>{subsublink.name}</li>
                                    </Link>
                                </button>
                            ))}
                        </ul>
                    </Implants>
                </div>
            ) : (
                <Overlap
                    regular={links.name?.toLowerCase() === 'blog' && true}
                    active={active}
                    position={position}
                >
                    <div>
                        <label>
                            {`${links.name}`}{' '}
                            {links.name?.toLowerCase() === 'blog' && (
                                <span style={{ fontSize: '14px' }}>(scroll through)</span>
                            )}
                        </label>
                        <ul>
                            {links.dropdown &&
                                links.dropdown.map((sublink: PagePath) => (
                                    <button key={sublink.name}>
                                        <Link to={sublink.url}>
                                            <li onClick={() => setActive(false)}>{sublink.name}</li>
                                        </Link>
                                    </button>
                                ))}
                        </ul>
                    </div>
                </Overlap>
            )}
        </Modal>
    );
};

export default DesktopModal;
