exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-appointments-tsx": () => import("./../../../src/pages/appointments.tsx" /* webpackChunkName: "component---src-pages-appointments-tsx" */),
  "component---src-pages-blogs-tsx": () => import("./../../../src/pages/blogs.tsx" /* webpackChunkName: "component---src-pages-blogs-tsx" */),
  "component---src-pages-dental-technology-tsx": () => import("./../../../src/pages/dental-technology.tsx" /* webpackChunkName: "component---src-pages-dental-technology-tsx" */),
  "component---src-pages-gallery-tsx": () => import("./../../../src/pages/gallery.tsx" /* webpackChunkName: "component---src-pages-gallery-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-sunbit-financing-tsx": () => import("./../../../src/pages/sunbit-financing.tsx" /* webpackChunkName: "component---src-pages-sunbit-financing-tsx" */),
  "component---src-pages-testimonials-tsx": () => import("./../../../src/pages/testimonials.tsx" /* webpackChunkName: "component---src-pages-testimonials-tsx" */),
  "component---src-templates-blog-details-tsx": () => import("./../../../src/templates/blog-details.tsx" /* webpackChunkName: "component---src-templates-blog-details-tsx" */),
  "component---src-templates-doctors-details-tsx": () => import("./../../../src/templates/doctors-details.tsx" /* webpackChunkName: "component---src-templates-doctors-details-tsx" */),
  "component---src-templates-patient-resources-tsx": () => import("./../../../src/templates/patient-resources.tsx" /* webpackChunkName: "component---src-templates-patient-resources-tsx" */),
  "component---src-templates-services-details-tsx": () => import("./../../../src/templates/services-details.tsx" /* webpackChunkName: "component---src-templates-services-details-tsx" */)
}

