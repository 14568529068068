/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
//styles
import styled from '@emotion/styled';
import { colors, device } from '../../GlobalStyles';
import { Link } from 'gatsby';
//components
import { ContactForm } from './ContactForm';
import { Map } from './Map';
//images
import whiteLogo from '../../../images/logo-white.png';
import arrow from '../../../images/icons/white-arrow-up.svg';
import modernDoc from '../../../images/icons/mdm.svg';
import { CurrentYear } from './Current-Year';

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
};

const StyledFooter = styled.footer`
    background: ${colors.grey};
    display: flex;

    flex-direction: column;
    p {
        width: 75vw;
        margin: 0 auto;
        text-align: center;
        font-size: 11px;
        line-height: 18px;
        margin-top: 1.5em;
        color: ${colors.white};
    }
    .mdm {
        display: flex;
        position: relative;
        justify-content: center;
        padding-bottom: 1.5em;
        img {
            position: absolute;
            margin-right: 16em;
        }
    }

    button {
        transition: none;
        &:hover {
            background: ${colors.yellow};
        }
        margin: 0 auto;
        margin-top: 4em;
        border: none;
        background: ${colors.yellowGradient};
        cursor: pointer;
        padding: 1em;
    }
    h6 {
        color: ${colors.white};
    }
    .flex {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .social {
        margin: 0 auto;

        div {
            cursor: pointer;
            margin: 0.75em 0.8em;
            a {
                color: white;
                &:hover {
                    color: ${colors.yellow};
                }
            }
        }
    }
    .logo {
        margin-top: 3em;
        width: 197px;
    }
    h3 {
        margin-top: 3em;
        font-size: 11px;
        color: ${colors.yellow};
        letter-spacing: 3px;
    }
    ul {
        padding-left: 0px;
    }
    .first-column {
        display: none;
    }
    li {
        span {
            color: ${colors.white};
            font-weight: 700;
        }
        cursor: pointer;
        color: ${colors.white};

        list-style: none;
        margin: 1.5em 0em;
    }
    .right-column {
        display: none;
    }

    @media ${device.tablet} {
        position: relative;
        button {
            position: absolute;
            left: 2em;
            bottom: 2em;
        }
        h6 {
            font-size: 13px;
        }
        .mdm {
            margin-top: 2em;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .flex {
            text-align: left;
            flex-direction: row;
            justify-content: space-around;
        }
        .logo {
            margin-bottom: 5em;
        }
        .social {
            margin-left: -0.5em;
        }

        .mobile {
            display: none;
        }
        .right-column {
            display: flex;
            width: 30vw;
            justify-content: space-between;
        }
        .first-column {
            display: block;
            position: relative;
            top: 3em;
        }
        p {
            text-align: center;
        }
    }
    @media ${device.desktop} {
        .mdm {
        }
    }
    @media ${device.desktopL} {
        .mdm {
        }
        h3 {
            font-size: 14px;
        }
        li {
            font-size: 16px;
        }
    }
`;

const Button = styled.button();

export const Footer: React.FC = () => {
    return (
        <>
            <ContactForm />
            <Map />
            <StyledFooter>
                <div className="flex">
                    <div className="left-column">
                        <img className="logo" src={whiteLogo} alt="white graphic logo" />
                        <div>
                            <h3>FIND OUT MORE:</h3>
                            <ul className="social">
                                <div>
                                    <span>
                                        <a
                                            href="https://www.facebook.com/SmileTampa"
                                            target="__blank"
                                        >
                                            Facebook
                                        </a>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <a
                                            href="https://www.healthgrades.com/group-directory/fl-florida/tampa/prosthodontics-implant-therapy-y5cmgk"
                                            target="__blank"
                                        >
                                            Health Grades
                                        </a>
                                    </span>
                                </div>
                                <div>
                                    <span>
                                        <a
                                            href="https://www.google.com/maps/place/Prosthodontics+and+Implant+Therapy/@28.025022,-82.489328,14z/data=!4m5!3m4!1s0x0:0xb3051970e1eb3aad!8m2!3d28.025022!4d-82.4893281?hl=en-US&shorturl=1"
                                            target="__blank"
                                        >
                                            Google reviews
                                        </a>
                                    </span>
                                </div>
                            </ul>
                            <div className="mobile">
                                <h3>COMPANY</h3>
                                <ul>
                                    <Link to="/about-us/">
                                        <li>About us</li>
                                    </Link>
                                    <Link to="/services/">
                                        <li>Services</li>
                                    </Link>
                                    <li>
                                        <a style={{ color: `${colors.white}` }} href="#contact-us">
                                            Contact us
                                        </a>
                                    </li>
                                </ul>
                                <h3>LOCATIONS</h3>
                                <ul>
                                    <li>
                                        <span style={{ color: `${colors.yellow}` }}>
                                            Prosthodontics & Implant Therapy
                                            <br />
                                        </span>
                                        <a href="https://www.google.com/maps/place/Prosthodontics+and+Implant+Therapy/@28.025022,-82.489328,14z/data=!4m5!3m4!1s0x0:0xb3051970e1eb3aad!8m2!3d28.025022!4d-82.4893281?hl=en-US&shorturl=1">
                                            <span>
                                                2814 W Waters Ave
                                                <br />
                                                Tampa, FL 33614-1853
                                            </span>
                                        </a>
                                        <br />
                                        <a href="tel:813-933-6705">
                                            <span>Phone: (813) 933-6705</span>
                                        </a>
                                        <br />
                                        Fax: (813) 933-8696
                                    </li>

                                    <li>
                                        <span style={{ color: `${colors.yellow}` }}>
                                            Prosthodontics & Implant Therapy
                                            <br />
                                        </span>
                                        <a href="https://www.google.com/maps/place/11948+Boyette+Rd,+Riverview,+FL+33569,+USA/@27.85459,-82.3018767,17z/data=!3m1!4b1!4m5!3m4!1s0x88c2d22a48bb6155:0xe871206708bc6e5c!8m2!3d27.85459!4d-82.299688?hl=en-US">
                                            <span>
                                                11948 Boyette Road
                                                <br />
                                                Riverview, FL 33569
                                            </span>
                                        </a>
                                        <br />
                                        <a href="tel:813-373-5525">
                                            <span>Phone: (813) 373-5525</span>
                                        </a>
                                        <br />
                                        Fax: (813) 373-6895
                                    </li>

                                    <li>
                                        <span style={{ color: `${colors.yellow}` }}>
                                            Prosthodontics & Implant Therapy
                                            <br />
                                        </span>
                                        <a href="https://www.google.com/maps/place/20727+Sterlington+Dr,+Land+O'+Lakes,+FL+34638,+USA/@28.1673669,-82.4691579,13z/data=!4m5!3m4!1s0x88c2bbf8f82662dd:0xde7788d4d93c2904!8m2!3d28.1946127!4d-82.4732027?hl=en-US">
                                            <span>
                                                20727 Sterlington Dr.
                                                <br />
                                                Land O Lakes, FL 34638
                                            </span>
                                        </a>
                                        <br />
                                        <a href="tel:813-575-9399">
                                            <span>Phone: (813) 575-9399</span>
                                        </a>
                                        <br />
                                        Fax: (813) 510-5540
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="first-column">
                        <h3>LOCATIONS</h3>
                        <ul>
                            <li>
                                <span style={{ color: `${colors.yellow}` }}>
                                    Prosthodontics & Implant Therapy
                                    <br />
                                </span>
                                <a href="https://www.google.com/maps/place/Prosthodontics+and+Implant+Therapy/@28.025022,-82.489328,14z/data=!4m5!3m4!1s0x0:0xb3051970e1eb3aad!8m2!3d28.025022!4d-82.4893281?hl=en-US&shorturl=1">
                                    <span>
                                        2814 W Waters Ave
                                        <br />
                                        Tampa, FL 33614-1853 <br />
                                    </span>
                                </a>
                                <a href="tel:813-933-6705">
                                    <span>Phone: (813) 933-6705</span>
                                </a>
                                <br />
                                Fax: (813) 933-8696
                            </li>

                            <li>
                                <span style={{ color: `${colors.yellow}` }}>
                                    Prosthodontics & Implant Therapy
                                    <br />
                                </span>
                                <a href="https://www.google.com/maps/place/11948+Boyette+Rd,+Riverview,+FL+33569,+USA/@27.85459,-82.3018767,17z/data=!3m1!4b1!4m5!3m4!1s0x88c2d22a48bb6155:0xe871206708bc6e5c!8m2!3d27.85459!4d-82.299688?hl=en-US">
                                    <span>
                                        11948 Boyette Road
                                        <br />
                                        Riverview, FL 33569
                                    </span>
                                </a>
                                <br />
                                <a href="tel:813-373-5525">
                                    <span>Phone: (813) 373-5525</span>
                                </a>
                                <br />
                                Fax: (813) 373-6895
                            </li>

                            <li>
                                <span style={{ color: `${colors.yellow}` }}>
                                    Prosthodontics & Implant Therapy
                                    <br />
                                </span>
                                <a href="https://www.google.com/maps/place/20727+Sterlington+Dr,+Land+O'+Lakes,+FL+34638,+USA/@28.1673669,-82.4691579,13z/data=!4m5!3m4!1s0x88c2bbf8f82662dd:0xde7788d4d93c2904!8m2!3d28.1946127!4d-82.4732027?hl=en-US">
                                    <span>
                                        20727 Sterlington Dr.
                                        <br />
                                        Land O Lakes, FL 34638
                                    </span>
                                </a>
                                <br />
                                <a href="tel:813-575-9399">
                                    <span>Phone: (813) 575-9399</span>
                                </a>
                                <br />
                                Fax: (813) 510-5540
                            </li>
                        </ul>
                    </div>
                    <div className="right-column">
                        <div>
                            <h3>COMPANY</h3>
                            <ul>
                                <li>
                                    <Link style={{ color: `${colors.white}` }} to="/about-us/">
                                        About us
                                    </Link>
                                </li>
                                <li>
                                    <Link style={{ color: `${colors.white}` }} to="/services/">
                                        Services
                                    </Link>
                                </li>
                                <li>
                                    <a style={{ color: `${colors.white}` }} href="#contact">
                                        Contact us
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div>
                            {/* make this below display none on mobile */}
                            <h3>SERVICES</h3>
                            <ul>
                                <Link to="/general-dentistry/">
                                    <li>General Dentistry</li>
                                </Link>
                                <Link to="/cosmetic-dentistry/">
                                    <li>Cosmetic Dentistry</li>
                                </Link>
                                <Link to="/dental-implants/">
                                    <li>Dental Implants</li>
                                </Link>
                                <Link to="/dental-technology/">
                                    <li>Technology</li>
                                </Link>
                            </ul>
                        </div>
                    </div>
                </div>
                <Button onClick={() => scrollToTop()}>
                    <img src={arrow} alt="arrow point up" />{' '}
                </Button>
                <p style={{ marginTop: '6.02em' }}>
                    © Copyright <CurrentYear /> Smile Tampa Prosthodontics & Implant Therapy - All
                    Rights Reserved.
                </p>
                <div className="mdm">
                    <img src={modernDoc} alt="modern doc media logo" />
                    <h6>POWERED BY MODERN DOC MEDIA</h6>
                </div>
            </StyledFooter>
        </>
    );
};
