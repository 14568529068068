import { css, Global } from '@emotion/react';
// import emotionNormalize from "emotion-normalize"
import React from 'react';

export const colors = {
    grey: '#171B24',
    white: '#FFFFFF',
    greyBackground: '#F6F6F6',
    green: '#006747',
    yellow: '#FEE444',
    yellowGradient: 'linear-gradient(180deg, #FEE444 0%, #B18E32 100%)',
};

const size = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1020px',
    laptopL: '1280px',
    desktop: '1540px',
    desktopL: '1720px',
};

export const device = {
    mobileS: `(min-width: ${size.mobileS})`,
    mobileM: `(min-width: ${size.mobileM})`,
    mobileL: `(min-width: ${size.mobileL})`,
    tablet: `(min-width: ${size.tablet})`,
    laptop: `(min-width: ${size.laptop})`,
    laptopL: `(min-width: ${size.laptopL})`,
    desktop: `(min-width: ${size.desktop})`,
    desktopL: `(min-width: ${size.desktopL})`,
};

export const GlobalStyles: React.FC = () => {
    return (
        <Global
            styles={css`
                html {
                    box-sizing: border-box;
                    font-size: 100%;
                    overflow-x: hidden;
                    -ms-text-size-adjust: 100%;
                    -webkit-text-size-adjust: 100%;
                    scroll-behavior: smooth;
                }
                body {
                    background: ${colors.greyBackground};
                    color: ${colors.grey};
                    font-family: 'Manrope', sans-serif;
                    font-weight: normal;
                    font-size: 1rem;
                    line-height: 161%;
                    word-wrap: break-word;
                    font-kerning: normal;
                    overflow-x: hidden;
                    margin: 0;
                    -webkit-font-smoothing: antialiased;
                }
                button {
                    outline: 'none';
                }
                * {
                    margin: 0;
                    transition: 0.2s all;
                }

                h1,
                h2,
                h3,
                h4,
                h5 {
                    font-weight: 700;
                    color: ${colors.grey};
                    letter-spacing: -1px;
                }

                h1 {
                    font-size: 2rem;
                    line-height: 2.625rem;
                }

                h2 {
                    /* same on desktop */
                    font-size: 3rem;
                    line-height: 3.625rem;
                }

                h3 {
                    font-size: 2rem;
                    line-height: 2.625rem;
                }
                h4 {
                    text-decoration: 8px underline ${colors.yellow};
                    text-transform: uppercase;
                    font-weight: 700;
                    letter-spacing: 6px;
                    font-size: 0.8125rem;
                    color: ${colors.green};
                }
                h5 {
                    font-size: 1.5rem;
                    line-height: 2rem;
                }
                a {
                    text-decoration: none;
                    color: black;
                }

                *,
                *:before,
                *:after {
                    box-sizing: border-box;
                }

                img {
                    max-width: 100%;
                    display: block;
                }

                button,
                input,
                optgroup,
                select,
                textarea {
                    margin: 0;
                }

                button,
                input {
                    overflow: visible;
                }

                ::-webkit-input-placeholder {
                    color: inherit;
                    opacity: 0.54;
                }

                ::-webkit-file-upload-button {
                    -webkit-appearance: button;
                    font: inherit;
                }

                .container-slim {
                    max-width: 1400px;
                    margin: 0 auto;
                    @media (min-width: 1660px) {
                        padding: 0;
                    }
                }

                @media (min-width: 1280px) {
                    h1 {
                        font-size: 4rem;
                        line-height: 5.25rem;
                    }
                    p {
                        font-size: 1.3125rem;
                        line-height: 2.125rem;
                    }
                }
                @media (min-width: 1580px) {
                    h1 {
                        font-size: 4.375rem;
                        line-height: 5.25rem;
                    }

                    p {
                        font-size: 1.3125rem;
                        line-height: 2.125rem;
                    }
                }
            `}
        />
    );
};
