export const navlinks = [
    {
        id: 0,
        name: 'SERVICES',
        position: '12vw',
        dropdown: [
            {
                id: 0,
                name: 'GENERAL DENTISTRY',
                subdropdown: [
                    { id: 0, name: 'Cleaning & Exams', url: '/general-dentistry/cleaning-exams/' },
                    { id: 1, name: 'Dental Fillings', url: '/general-dentistry/dental-fillings/' },
                    { id: 2, name: 'Teeth Whitening', url: '/general-dentistry/teeth-whitening/' },
                    { id: 3, name: 'Periodontal', url: '/general-dentistry/periodontal-care/' },
                    {
                        id: 4,
                        name: 'Invisalign Aligners',
                        url: '/general-dentistry/invisalign-clear-aligners/',
                    },
                    { id: 5, name: 'TMJ TMD', url: '/general-dentistry/tmj-tmd/' },
                ],
            },
            {
                id: 1,
                name: 'COSMETIC DENTISTRY',
                subdropdown: [
                    { id: 0, name: 'Smile Makeover', url: '/cosmetic-dentistry/smile-makeover/' },
                    { id: 1, name: 'Dental Bonding', url: '/cosmetic-dentistry/dental-bonding/' },
                    {
                        id: 2,
                        name: 'Porcelain Veneers',
                        url: '/cosmetic-dentistry/porcelain-veneers/',
                    },
                    { id: 3, name: 'Ceramic Veneers', url: '/cosmetic-dentistry/ceramic-veneers/' },
                    { id: 4, name: 'Implants Crowns', url: '/cosmetic-dentistry/implant-crowns/' },
                    { id: 5, name: 'Crowns & Bridges', url: '/cosmetic-dentistry/crowns-bridges/' },
                    {
                        id: 6,
                        name: 'Supported Dentures',
                        url: '/cosmetic-dentistry/implant-retained-dentures/',
                    },
                    {
                        id: 7,
                        name: 'Porcelain Bridges',
                        url: '/cosmetic-dentistry/porcelain-bridges/',
                    },
                ],
            },
            {
                id: 2,
                name: 'DENTAL IMPLANTS',
                subdropdown: [
                    {
                        id: 0,
                        name: 'Single Tooth',
                        url: '/dental-implants/single-tooth/',
                    },
                    {
                        id: 1,
                        name: 'Multiple Teeth',
                        url: '/dental-implants/multiple-teeth/',
                    },
                    {
                        id: 2,
                        name: 'Partial Fixed Bridge',
                        url: '/dental-implants/partial-fixed-bridge/',
                    },
                    {
                        id: 3,
                        name: 'Complete Fixed Bridge',
                        url: '/dental-implants/multiple-teeth/',
                    },
                    {
                        id: 4,
                        name: 'Overdentures',
                        url: '/dental-implants/overdentures/',
                    },
                    {
                        id: 5,
                        name: 'Implant Restoration',
                        url: '/dental-implants/implant-restoration/',
                    },
                    {
                        id: 6,
                        name: 'Mini Dental Implants',
                        url: '/dental-implants/mini-dental-implants/',
                    },
                ],
            },
        ],
    },
    //this is where the services dropdown object ends and procedures comes in
    {
        id: 1,
        name: 'ABOUT',
        position: '25vw',
        dropdown: [
            {
                id: 0,
                name: 'About Us',
                url: '/about-us/',
            },
            {
                id: 1,
                name: 'Meet Dr. Reza Iranmanesh',
                url: '/about-us/dr-m-reza-iranmanesh/',
            },
            {
                id: 3,
                name: 'Meet Dr. Nima Iranmanesh',
                url: '/about-us/dr-nima-iranmanesh/',
            },
            {
                id: 4,
                name: 'Meet Dr. Nika Iranmanesh',
                url: '/about-us/dr-nika-iranmanesh/',
            },
            {
                id: 5,
                name: 'Meet Dr. Armita Mashkouri',
                url: '/about-us/dr-armita-mashkouri/',
            },
            {
                id: 6,
                name: 'Testimonials',
                url: '/testimonials/',
            },
            {
                id: 7,
                name: 'Technology',
                url: '/dental-technology/',
            },
        ],
    },
    {
        id: 2,
        name: 'RESOURCES',
        position: '36vw',
        dropdown: [
            {
                id: 0,
                name: 'Your 1st Visit',
                url: '/patient-resources/your-first-visit/',
            },
            {
                id: 1,
                name: 'Financial & Insurance',
                url: '/patient-resources/financial-insurance/',
            },
            {
                id: 2,
                name: 'Forms',
                url: '/patient-resources/forms/',
            },
            {
                id: 3,
                name: 'Gallery',
                url: '/gallery/',
            },
            {
                id: 4,
                name: 'Sedation Options',
                url: '/patient-resources/sedation-options/',
            },
            {
                id: 0,
                name: 'Sunbit Financing',
                url: '/sunbit-financing/',
            },
        ],
    },
    {
        id: 2,
        name: 'BLOG',
        position: '50vw',
        dropdown: [
            {
                id: 21,
                name: 'Full Mouth Reconstruction: What to Expect and Is It Right for You?',
                url: '/blogs/full-mouth-reconstruction-what-to-expect-and-is-it-right-for-you/',
            },
            {
                id: 20,
                name: 'Dental Implants vs. Dentures: Which Is Right for You?',
                url: '/blogs/dental-implants-vs-dentures/',
            },
            {
                id: 19,
                name: 'The Importance of Regular Dental Cleanings: Your Guide to a Healthy Smile',
                url: '/blogs/the-importance-of-regular-dental-cleanings/',
            },
            {
                id: 18,
                name: 'Understanding and Managing Teeth Grinding',
                url: '/blogs/understanding-and-managing-teeth-grinding/',
            },
            {
                id: 17,
                name: 'The Benefits of Tongue Scraping',
                url: '/blogs/benefits-of-tongue-scraping/',
            },
            {
                id: 16,
                name: 'Transforming Smiles with Expertise and Innovation',
                url: '/blogs/transforming-smiles-with-expertise-and-innovation/',
            },
            { id: 15, name: 'New Dentist? No Worries', url: '/blogs/new-dentist-no-worries/' },
            {
                id: 14,
                name: 'Single Tooth Implants: The Gold Standard of Dental Restoration',
                url: '/blogs/single-tooth-implants/',
            },
            {
                id: 13,
                name: 'Navigating Dental Anxiety - A Comprehensive Guide to Sedation Dentistry',
                url: '/blogs/navigating-dental-anxiety/',
            },
            {
                id: 12,
                name: 'Periodontal Disease: In-Depth Understanding and Management',
                url: '/blogs/periodontal-disease-in-depth-understanding-and-management/',
            },
            {
                id: 11,
                name: 'Natural-Looking Results with Implant Restorations',
                url: '/blogs/achieving-natural-looking-results-implant-restorations/',
            },
            { id: 10, name: 'Relief for TMJ and TMD', url: '/blogs/relief-for-tmj-and-tmd' },
            {
                id: 9,
                name: 'Guide to Dental Bonding',
                url: '/blogs/your-ultimate-guide-to-dental-bonding-at-smile-tampa/',
            },
            {
                id: 8,
                name: 'Tips for Caring for Your Dental Crowns: Your Comprehensive Guide',
                url: '/blogs/tips-for-caring-for-dental-crowns/',
            },
            {
                id: 7,
                name: 'Top 5 Oral Health Tips To Maintain A Healthy Smile',
                url: '/blogs/top-5-oral-health-tips/',
            },
            {
                id: 6,
                name: 'Smile Confidently Again: The Benefits of Dental Implants ',
                url: '/blogs/benefits-dental-implants/',
            },
            {
                id: 5,
                name: 'Difference Between General And Cosmetic Dentist',
                url: '/blogs/difference-between-general-and-cosmetic-dentist/',
            },
            {
                id: 4,
                name: 'Dental Fillings - How Much Are They? How Long Do They last?',
                url: '/blogs/dental-fillings-how-long-how-much/',
            },
            {
                id: 3,
                name: 'Treatment For Periodontal Gum Disease',
                url: '/blogs/treatment-periodontal/',
            },
            {
                id: 2,
                name: 'Protect Teeth From Sports Injuries',
                url: '/blogs/how-to-protect-teeth-from-sports-injuries/',
            },
            {
                id: 1,
                name: 'What to Know About Braces',
                url: '/blogs/what-to-know-about-braces/',
            },

            {
                id: 0,
                name: 'Dental Checklist',
                url: '/blogs/dental-checklist/',
            },
        ],
    },
];
