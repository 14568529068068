import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { colors, device } from '../../GlobalStyles';
import waves from '../../../images/icons/waves-top-right.svg';
import arrow from '../../../images/icons/black-arrow-right.svg';
//components
import { YellowButton } from '../StyledComponents';

const ContactSection = styled.section`
    input {
        border: 1px solid ${colors.green};
    }
    background: url(${waves}), ${colors.green};
    background-repeat: no-repeat;
    background-position: 900px 0px;
    color: ${colors.white};
    padding-bottom: 50px;
    span {
        font-weight: 400;
        font-size: 12px;
    }
    h2 {
        margin-left: 0.6em;
        padding-top: 76px;
        margin-bottom: 50px;

        font-size: 47px;
        color: ${colors.white};
    }
    @media (min-width: 400px) and (max-width: 520px) {
        h2 {
            font-size: 52px;
        }
    }
    svg {
        margin-right: 14px;
        fill: white;
    }
    a {
        text-decoration: none;
        color: white;
    }
    li {
        margin-bottom: 28px;
    }
    ul {
        padding-left: 1.5em;
        list-style-type: none;
        font-weight: 700;
        margin-bottom: 31px;
    }
    @media ${device.tablet} {
        padding: 8em 0em;
        h2 {
            width: 50%;
            display: none;
            margin-left: 0.8em;
            font-size: 48px;
        }
        display: flex;
        margin: 0 auto;
        justify-content: space-around;
        ul {
            margin-top: 5em;
            padding-left: 3em;
        }
    }

    @media ${device.laptopL} {
        h2 {
            display: block;
            margin-bottom: 0.5em;
        }
        .contain {
            margin-top: -3em;
            margin-left: 3em;
        }
        ul {
            margin-top: 0em;
        }
    }
    @media ${device.desktop} {
        background-position: 1100px 0px;
        .contain {
            margin-left: 0em;
        }
        h2 {
            margin-left: 5em;
            margin-top: 0em;
            font-size: 46px;
        }
        ul {
            margin-left: 12em;
        }
    }
    @media ${device.desktopL} {
        background-position: 1300px 0px;
    }
`;

const StyledForm = styled.form`
    .thanks {
        padding: 37% 0em;
        color: ${colors.green};
        font-weight: 700;
        font-size: 2.4em;
        line-height: 1em;
    }
    margin: 0px 13.22px;
    background: ${colors.white};
    border-radius: 16px;
    color: ${colors.grey};
    padding: 48px 24px 45px;

    input,
    select,
    textarea {
        width: 100%;
        padding: 12px;
        border: 1px solid ${colors.grey};
        box-sizing: border-box;
        border-radius: 8px;
        border-radius: 4px;
        margin-top: 6px;
        margin-bottom: 16px;
        resize: vertical;
    }
    .textarea {
        border: 1px solid #c19d55;
    }
    .submitButton {
        display: flex;
        justify-content: center;
    }
    p {
        font-size: 16px;
        line-height: 26px;
        margin-bottom: 29px;
    }
    span {
        color: ${colors.grey};
    }
    input[type='submit']:hover {
        background-color: #45a049;
    }
    @media ${device.tablet} {
        width: 400px;
        margin-top: 2em;
        position: relative;
        padding: 40px 24px 60px;
        p {
            width: 80%;
        }
        .submitButton {
            position: absolute;
            left: 5%;
        }
    }
    @media ${device.laptop} {
        width: 496px;
        padding: 40px 24px 60px;
    }
    @media ${device.desktop} {
        margin-right: 12em;
    }
`;

const Button = styled(YellowButton)`
    padding: 1em 9em;
    @media (min-width: 400px) {
        padding: 1em 1.5em;
    }
    @media ${device.tablet} {
        padding: 2em 11em;
    }
    @media ${device.laptop} {
        padding: 2em 15em;
    }
    @media ${device.laptopL} {
        padding: 2em 15em;
    }
`;

const ButtonNoForm = styled(YellowButton)`
    padding: 1em 2em;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media (min-width: 400px) {
        padding: 1em 2em;
    }
    @media ${device.tablet} {
        padding: 1em 2em;
        margin-top: 18em;

        width: 210px;
        margin-right: 6em;
    }
    @media ${device.laptop} {
        padding: 2em 2em;
    }
    @media ${device.laptopL} {
        padding: 2em 2em;
        display: flex;
        justify-content: center;
        width: 280px;
    }
    @media ${device.desktop} {
        margin-top: 10em;
        margin-right: 15em;
    }
`;

export const ContactForm: React.FC<{ noForm?: boolean }> = ({ noForm }) => {
    const [submitted, setSubmitted] = useState(false);
    const onSubmit = useCallback(
        async e => {
            e.preventDefault();

            const data = Object.fromEntries(new FormData(e.target));

            await fetch(`https://api.${window.location.host}/contact`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(data),
            });

            e.target.reset();
            setSubmitted(true);
        },
        [setSubmitted]
    );
    return (
        <ContactSection>
            <span id="contact"></span>
            <div className="contain">
                <h2>
                    {noForm
                        ? 'Do you have a specific dental requirement?'
                        : 'Get in touch with us about anything.'}
                </h2>
                {!noForm && (
                    <ul>
                        <div>
                          <span>EMAIL ADDRESS</span>
                            <li>
                                <span style={{ fontSize: '17px' }}>Tampa: </span>
                                <a href="mailto:smiletampa@gmail.com">smiletampa@gmail.com</a>
                                <br />
                                <span style={{ fontSize: '17px' }}>Riverview: </span>
                                <a href="mailto:smileriverview@gmail.com">
                                    smileriverview@gmail.com
                                </a>
                                <br />
                                <span style={{ fontSize: '17px' }}>Land O’ Lakes: </span>
                                <a href="mailto:smilelandolakes@gmail.com">
                                    smilelandolakes@gmail.com
                                </a>
                            </li>
                            <span>PHONE</span>
                            <li>
                                <a href="tel:+18139336705">Tampa: (813) 933 6705</a>
                                <br />
                                <a href="tel:+18133735525">Riverview: (813) 373-5525</a>
                                <br />
                                <a href="tel:+18135759399">Land O’ Lakes: (813) 575 9399</a>
                            </li>
                        </div>
                        <span>ADDRESS</span>
                        <li>
                            <a href="https://www.google.com/maps/place/Prosthodontics+and+Implant+Therapy/@28.025022,-82.489328,14z/data=!4m5!3m4!1s0x0:0xb3051970e1eb3aad!8m2!3d28.025022!4d-82.4893281?hl=en-US&shorturl=1">
                                Tampa: 2814 W Waters Ave, Tampa, FL 33614
                            </a>
                            <br />
                            <a href="https://www.google.com/maps/place/11948+Boyette+Rd,+Riverview,+FL+33569,+USA/@27.85459,-82.3018767,17z/data=!3m1!4b1!4m5!3m4!1s0x88c2d22a48bb6155:0xe871206708bc6e5c!8m2!3d27.85459!4d-82.299688?hl=en-US">
                                Riverview: 11948 Boyette Rd Riverview, FL 33569
                            </a>
                            <br />
                            <a href="https://www.google.com/maps/place/20727+Sterlington+Dr,+Land+O'+Lakes,+FL+34638,+USA/@28.1673669,-82.4691579,13z/data=!4m5!3m4!1s0x88c2bbf8f82662dd:0xde7788d4d93c2904!8m2!3d28.1946127!4d-82.4732027?hl=en-US">
                                Land O’ Lakes: 20727 Sterlington Dr Land O’ Lakes, FL 34638
                            </a>
                        </li>

                        <span>FAX</span>
                        <li>
                            Tampa: 813-933-8696
                            <br />
                            Riverview: 813-373-6895
                            <br />
                            Land O’ Lakes: 813-510-5540
                        </li>
                        <span>HOURS</span>
                        <a href="https://www.google.com/maps?ll=28.02501,-82.48943&z=15&t=m&hl=en-US&gl=US&mapclient=embed&q=28%C2%B001%2730.0%22N+82%C2%B029%2722.0%22W+28.025010,+-82.489430@28.02501,-82.48943">
                            <li>
                                Monday: 8:00 am - 5:00 pm <br />
                                Tuesday: 8:00 am - 5:00 pm <br />
                                Wednesday: 8:00 am - 5:00 pm <br />
                                Thursday: 8:00 am - 5:00 pm <br />
                                Friday: 8:00 am - 1:00 pm (Only By Appointment)
                                <br />
                                Saturday: Closed <br />
                                Sunday: Closed
                            </li>
                        </a>
                    </ul>
                )}
            </div>
            <div className="no-form-flex">
                {!noForm ? (
                    <StyledForm onSubmit={onSubmit}>
                        {submitted ? (
                            <p style={{ lineHeight: '42px' }} className="thanks">
                                Thank You for your submission!
                            </p>
                        ) : (
                            <>
                                <label htmlFor="name">Your Name</label>
                                <input required id="name" name="Name" type="text" />

                                <label htmlFor="email">Email address</label>
                                <input required id="email" name="Email" type="email" />

                                <label htmlFor="phone">Phone number</label>
                                <input required id="phone" name="Phone" type="text" />

                                <label htmlFor="message">Message</label>
                                <textarea required id="message" name="Message" />
                                <div>
                                    <Button type="submit">
                                        SEND
                                        <img src={arrow} alt="black arrow pointing right" />
                                    </Button>
                                </div>
                            </>
                        )}
                    </StyledForm>
                ) : (
                    <div className="no-form-button">
                        <ButtonNoForm>
                            GET IN TOUCH
                            <img src={arrow} alt="black arrow pointing right" />
                        </ButtonNoForm>
                    </div>
                )}
            </div>
        </ContactSection>
    );
};
