import styled from '@emotion/styled';
import { colors } from '../GlobalStyles';

export const YellowButton = styled.button`
    background: ${colors.yellowGradient};
    border: none;
    padding: 0;
    font-size: 0.8125rem;
    line-height: 1.375rem;
    cursor: pointer;
    display: flex;
    outline: none;
    letter-spacing: 3px;
    text-transform: uppercase;
    &:hover {
        box-shadow: rgba(100, 100, 111, 0.2) 0px 10px 29px 0px;
    }
    img {
        margin-left: 10px;
        position: relative;
        top: 1px;
    }
`;

export const GreenButton = styled(YellowButton)`
    background: ${colors.green};
`;
