import React, { useState, useContext } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
//not sure how to use theme props w/ typescript... so being forces to import
//this way. Typescript giving and error (line 28)
import { colors, device } from '../../GlobalStyles';
//images
import phone from '../../../images/icons/phone.svg';
import phoneGreen from '../../../images/icons/mobile-green.svg';
import marker from '../../../images/icons/marker.svg';
import markerGreen from '../../../images/icons/markerGreen.svg';
import logo from '../../../images/logo.png';
import arrow from '../../../images/icons/black-arrow-down.svg';
import arrowWhite from '../../../images/icons/white-arrow-down.svg';
import { siteContext } from '../../../layouts/SiteContextProvider';
import { DesktopModal } from './DesktopModal';
import { navlinks } from './NavLinks';

const StyledDesktopNav = styled.div`
    a:first-child {
        position: relative;
        top: 13px;
    }
    display: none;
    @media ${device.laptop} {
        box-shadow: 0px 16px 16px rgba(0, 0, 0, 0.03);
        display: flex;
        justify-content: space-between;
        img:first-of-type {
            height: 62px;
            margin: auto 0;
            padding-left: 1em;
        }
        p {
            font-size: 0.8rem;
            line-height: 1.375rem;
        }
        .phone {
            .phone-panel {
                background: ${colors.white};
                position: absolute;
                border-radius: 4px;
                top: 5.9em;
                right: 0em;
                width: 250px;
                ul {
                    padding-left: 0em;
                }
                li {
                    width: 85%;
                    margin: 0 auto;
                    padding-bottom: 2.5em;
                    a {
                        margin-left: -0.65em;
                        display: flex;
                    }

                    list-style: none;

                    p {
                        text-align: left;
                        font-size: 1.3em;
                        font-weight: 600;
                        color: ${colors.green};
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                    span {
                        position: relative;
                        top: 0.25em;
                        font-size: 0.7em;
                        color: grey;
                    }
                }
            }
        }
        .address {
            margin-left: auto;

            .location-panel {
                background: ${colors.white};
                position: absolute;
                border-radius: 4px;
                top: 7em;
                right: 0;
                width: 250px;
                ul {
                    padding-left: 0em;
                }
                li {
                    width: 85%;
                    margin: 0 auto;
                    padding-bottom: 2.5em;
                    a {
                        margin-left: -0.65em;
                        display: flex;
                    }

                    list-style: none;

                    p {
                        text-align: left;
                        font-size: 1.3em;
                        font-weight: 600;
                        color: ${colors.green};
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                    span {
                        position: relative;
                        top: 0.25em;
                        font-size: 0.7em;
                        color: grey;
                    }
                }
            }
        }
        .phone p {
            font-size: 1.8em;
            font-weight: 600;
            margin-left: 0.5em;
        }
        .address p {
            margin-left: 1em;
        }
        .phone,
        .address {
            position: RELATIVE;
            padding: 0em 1em;

            img {
                height: 24px;
            }
        }
        /* Initial menu items (services, procedures, about, patient resources) */
        .first-menu {
            padding: 0em;
            margin: 0em;
            display: flex;
            justify-content: space-around;
            button {
                border: none;
                background: none;
                cursor: pointer;
                padding: 1.34em 3em;
                display: flex;
                font-size: 12px;
                font-weight: 700;
                align-items: center;

                img {
                    width: 1.4rem;
                }
                &:hover {
                    background: ${colors.yellowGradient};
                }
                &:focus-visible {
                    outline: none;
                    background: ${colors.yellowGradient};
                }
            }
        }
        .visible {
            display: block;
        }
        .invisible {
            display: none;
        }
        .address {
            display: flex;
            align-items: center;
            .address-arrow {
                width: 14px;
                margin-left: 1em;
            }
        }
        .phone {
            display: flex;
            align-items: center;
            background: ${colors.green};
            color: ${colors.white};
        }
    }

    @media (max-width: 1296px) {
        .address {
            > button > p {
                display: none;
            }
        }
        .phone {
            > button > p {
                display: none;
            }
        }
    }
    @media ${device.desktop} {
        p {
            font-size: 1rem;
            line-height: 1.375rem;
        }
    }
`;

export const DesktopNav: React.FC<{
    activeSlide: boolean;
    setActiveSlide: (active: boolean) => void;
}> = ({ setActiveSlide, activeSlide }) => {
    const [active, setActive] = useState(false);
    const [locations, setLocations] = useState(false);
    const [numbers, setNumbers] = useState(false);
    const [position, setPosition] = useState('0');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [links, setLinks] = useState<any>([]);
    const { sidePanels, setSidePanels } = useContext(siteContext);

    const showLocations = () => {
        setSidePanels(true);
        setLocations(true);
        setNumbers(false);
    };
    const showNumbers = () => {
        setSidePanels(true);
        setNumbers(true);
        setLocations(false);
    };
    const linkClicked = (
        position: React.SetStateAction<string>,
        links:
            | {
                  id: number;
                  name: string;
                  position: string;
                  dropdown: {
                      id: number;
                      //this way. Typescript giving and error (line 28)
                      name: string;
                      subdropdown: {
                          id: number;
                          //images
                          name: string;
                          url: string;
                      }[];
                  }[];
              }
            | {
                  id: number;
                  name: string;
                  position: string;
                  dropdown: { id: number; name: string; url: string }[];
              }
            | never[]
            | ((prevState: never[]) => never[])
    ) => {
        setActive(true);
        setPosition(position);
        setLinks(links);
    };
    return (
        <>
            <DesktopModal
                setActiveSlide={setActiveSlide}
                activeSlide={activeSlide}
                position={position}
                links={links}
                setLinks={setLinks}
                active={active}
                setActive={setActive}
            />
            <StyledDesktopNav className="container">
                <Link style={{ padding: '0' }} to="/">
                    <img src={logo} alt="Prosthodontics logo" />
                </Link>
                <nav className="first-menu">
                    {navlinks.map(link => (
                        <button
                            key={link.name}
                            onFocus={() => void 0}
                            onMouseOver={() => setSidePanels(false)}
                            onClick={() => linkClicked(link.position, link)}
                        >
                            {link.name}
                            <img src={arrow} alt="black arrow pointing down" />
                        </button>
                    ))}
                </nav>

                <div style={{ display: 'flex' }}>
                    <div className="address">
                        <button
                            style={{
                                cursor: 'pointer',
                                border: 'none',
                                background: 'none',
                                display: 'flex',
                            }}
                            onMouseEnter={() => showLocations()}
                        >
                            <img src={markerGreen} alt="map marker" />
                            <p>
                                Find us near you <br />
                            </p>
                            <img
                                className="address-arrow"
                                src={arrow}
                                alt="black arrow pointing down"
                            />

                            <div
                                className={`location-panel ${
                                    locations && sidePanels ? 'visible' : 'invisible'
                                }`}
                            >
                                <ul>
                                    <li>
                                        <a
                                            href="https://www.google.com/maps/place/Prosthodontics+and+Implant+Therapy/@28.025022,-82.489328,14z/data=!4m5!3m4!1s0x0:0xb3051970e1eb3aad!8m2!3d28.025022!4d-82.4893281?hl=en-US&shorturl=1"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {' '}
                                            <img src={markerGreen} alt="map marker" />
                                            <p>
                                                2814 W Waters Ave Tampa, FL 33614 <br />
                                                <span>Tampa (Main)</span>
                                            </p>
                                        </a>
                                    </li>
                                    <li
                                        style={{
                                            borderTop: '1px solid lightgrey',
                                            borderBottom: '1px solid lightgrey',
                                        }}
                                    >
                                        <a
                                            href="https://www.google.com/maps/place/11948+Boyette+Rd,+Riverview,+FL+33569,+USA/@27.85459,-82.3018767,17z/data=!3m1!4b1!4m5!3m4!1s0x88c2d22a48bb6155:0xe871206708bc6e5c!8m2!3d27.85459!4d-82.299688?hl=en-US"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {' '}
                                            <img src={markerGreen} alt="map marker" />
                                            <p>
                                                11948 Boyette Rd, Riverview, FL 33569 <br />
                                                <span>Riverview (South)</span>
                                            </p>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.google.com/maps/place/20727+Sterlington+Dr,+Land+O'+Lakes,+FL+34638,+USA/@28.1673669,-82.4691579,13z/data=!4m5!3m4!1s0x88c2bbf8f82662dd:0xde7788d4d93c2904!8m2!3d28.1946127!4d-82.4732027?hl=en-US"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {' '}
                                            <img src={markerGreen} alt="map marker" />
                                            <p>
                                                20727 Sterlington
                                                <br /> Dr, Land O' Lakes, FL 34638 <br />
                                                <span>Land O’ Lakes (North)</span>
                                            </p>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </button>
                    </div>

                    <div className="phone">
                        <button
                            style={{
                                cursor: 'pointer',
                                border: 'none',
                                background: 'none',
                                display: 'flex',
                            }}
                            onMouseEnter={() => showNumbers()}
                        >
                            <img src={phone} alt="mobile phone icon" />
                            <p style={{ color: `${colors.white}` }}>Call or Text Us at</p>
                            <img
                                style={{ width: '18px', marginLeft: '1em' }}
                                className="address-arrow"
                                src={arrowWhite}
                                alt="black arrow pointing down"
                            />
                        </button>
                        <div
                            className={`phone-panel ${
                                numbers && sidePanels ? 'visible' : 'invisible'
                            }`}
                        >
                            <ul>
                                <li>
                                    <a href="tel:8139336705">
                                        {' '}
                                        <img src={phoneGreen} alt="map marker" />
                                        <p>
                                            (813) 933-6705 <br />
                                            <span>Tampa (Main)</span>
                                        </p>
                                    </a>
                                </li>
                                <li
                                    style={{
                                        borderTop: '1px solid lightgrey',
                                        borderBottom: '1px solid lightgrey',
                                    }}
                                >
                                    {' '}
                                    <a href="tel:8133735525">
                                        <img src={phoneGreen} alt="map marker" />
                                        <p>
                                            (813) 373-5525 <br />
                                            <span>Riverview (South)</span>
                                        </p>
                                    </a>
                                </li>
                                <li>
                                    <a href="tel:8135759399">
                                        {' '}
                                        <img src={phoneGreen} alt="map marker" />
                                        <p>
                                            (813) 575-9399
                                            <br />
                                            <span>Land O’ Lakes (North)</span>
                                        </p>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </StyledDesktopNav>
        </>
    );
};
