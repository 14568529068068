import React, { useState, useEffect } from 'react';

export const CurrentYear = () => {
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

    useEffect(() => {
        // Update the current year when the component mounts
        setCurrentYear(new Date().getFullYear());
    }, []);

    return <>{currentYear}</>;
};
