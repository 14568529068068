import { Nav } from '../components/layout/nav/Nav';
import { useRef, useState, useContext } from 'react';
import { Footer } from '../components/layout/footer/Footer';
import { ThemeProvider } from '@emotion/react';
import { MDXProvider } from '@mdx-js/react';
import { siteContext } from './SiteContextProvider';
import { GlobalStyles, colors } from '../components/GlobalStyles';

export const DefaultLayout: React.FC = ({ children }) => {
    const { setSidePanels } = useContext(siteContext);

    return (
        <div id="layout-alt">
            <ThemeProvider theme={colors}>
                <GlobalStyles />
                <Nav />
                <div
                    style={{
                        // width: '100vw',
                        // height: '100vh',
                        padding: 'none',
                        margin: '0',
                        background: 'none',
                        border: 'none',
                    }}
                    onFocus={() => void 0}
                    onMouseOver={() => setSidePanels(false)}
                >
                    {children}
                    <Footer />
                </div>
            </ThemeProvider>
        </div>
    );
};
