import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
//not sure how to use theme props w/ typescript... so being forces to import
//this way. Typescript giving and error (line 28)
import { colors, device } from '../../GlobalStyles';
//images
import phone from '../../../images/icons/phone-icon.svg';
import logo from '../../../images/logo.png';

interface Props {
    setActiveSlide(arg: boolean): boolean;
    activeSlide: boolean;
}

export const MobileNav: React.FC<Props> = ({ activeSlide, setActiveSlide }) => {
    const StyledMobileNav = styled.div`
        display: flex;
        justify-content: space-between;
        width: 100vw;
        .phone {
            background: ${colors.green};
            padding: 1.1em;
        }
        .logo {
            height: 45px;
            margin: auto 0;
        }
        .burger {
            z-index: 200;
            border: none;
            padding: 0.98em 1.1em;
            /* background: ${props => props.theme.green}; */
            background: ${colors.green};
            transition: 0.2s ease-out;
            .line-one {
                opacity: ${({ activeSlide }) => activeSlide && '0'};
            }
            .line-two {
                transform: ${({ activeSlide }) =>
                    activeSlide && 'rotate(225deg) translate(0px, -0px)'};
            }
            .line-three {
                transform: ${({ activeSlide }) =>
                    activeSlide && 'rotate(-45deg) translate(4px, -3px)'};
            }
            div {
                background: ${colors.white};
                width: 16px;
                margin: 3.5px 0em;
                height: 2px;
            }
        }
        @media ${device.laptop} {
            display: none;
        }
    `;

    return (
        <StyledMobileNav activeSlide={activeSlide}>
            <a href="tel:8139336705">
                <img className="phone" src={phone} alt="mobile phone" />
            </a>
            <Link to="/" exact>
                <img className="logo" src={logo} alt="Prosthodontics logo" />
            </Link>
            <button onClick={() => setActiveSlide(activeSlide => !activeSlide)} className="burger">
                <div className="line-one"></div>
                <div className="line-two"></div>
                <div className="line-three"></div>
            </button>
        </StyledMobileNav>
    );
};
